import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  return (
    <Layout>
      <Seo title="Home" />
      <div
        style={{
          "font-family": "Courier",
          "display": "flex",
          "flex-direction": "column",
          "align-items": "center",
          "justify-content": "center",
          "color": "#e274c0",
          "height": "100vh"
        }}
      >
        <h1 
          style={{
            "font-size": "54px",
            "margin-bottom": "0"
          }}
        >
          {data.site.siteMetadata?.title}
        </h1>
        <h4>Sophisticated Digital Experiences</h4>
        <p>new site coming soon</p>
      </div>
    </Layout>
  )
}

export default IndexPage
